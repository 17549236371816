<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10">
        <v-row dense>
          <v-col>
            <h1 class="text-center toolbar--text">{{ $t('Disclaimer') }}</h1>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <p class="font-weight-bold">No Investment Advice</p>
            <p>
              The information provided on this website does not constitute investment advice, financial advice, trading advice, or any other sort of advice and you should not treat any of the website's content as such. BlockchainMetrics does not recommend that any cryptocurrency should be bought, sold, or held by you. Do conduct your own due diligence and consult your financial advisor before making any investment decisions.
            </p>
            <p class="font-weight-bold">Accuracy of Information</p>
            <p>
              BlockchainMetrics will strive to ensure accuracy of information listed on this website although it will not hold any responsibility for any missing or wrong information. BlockchainMetrics provides all information as is. You understand that you are using any and all information available here at your own risk.
            </p>
            <p class="font-weight-bold">Non Endorsement</p>
            <p>
              The appearance of third party advertisements and hyperlinks on BlockchainMetrics does not constitute an endorsement, guarantee, warranty, or recommendation by BlockchainMetrics. Do conduct your own due diligence before deciding to use any third party services.
            </p>
            <p class="font-weight-bold">Affiliate Disclosure</p>
            <p>
              BlockchainMetrics may receive compensation for affiliate links. This compensation may be in the form of money or services and could exist without any action from a site visitor. Should you perform activities in relation to an affiliate link, it is understood that some form of compensation might be made to BlockchainMetrics. For example, if you click on an affiliate link, and sign up and trade on an exchange, BlockchainMetrics may receive compensation. Each affiliate link is clearly marked with an icon next to it.
            </p>

            <p class="mb-0">
              <span class="red--text">IMPORTANT DISCLAIMER:</span> Information provided on BlockchainMetrics.com is not financial advice.
              cryptocurrency Investment comes with high risk. All content provided herein our website, hyperlinked sites,
              forums, blogs, associated applications, social media accounts and other platforms (“Site”) is for your general
              information only, procured from third party sources. We make no warranties of any kind in relation to our content,
              including but not limited to accuracy and updatedness. No part of the content that we provide constitutes financial advice,
              legal advice or any other form of advice meant for your specific reliance for any purpose. Any use or reliance on our content
              is solely at your own risk and discretion. You should conduct your own research, review, analyse and verify our content
              before relying on them. Trading is a highly risky activity that can lead to major losses, please therefore consult your
              financial advisor before making any decision. No content on our Site is meant to be a solicitation or offer.
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name:'Disclaimer',
}
</script>

<style lang="scss">
  @import "@/assets/styles/variables.scss";
</style>
